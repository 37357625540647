import { FaroButton } from "@components/common/faro-button";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Skeleton,
} from "@mui/material";
import {
  OPEN_PROJECT_TARGET_ATTRIBUTE,
  OpenProjectProps,
  getOpenProjectHref,
  getOpenProjectTargetName,
  trackOpenProject,
} from "@utils/project-utils";
import ArrowDropDownIcon from "@assets/icons/new/arrow-down_21px.svg?react";
import { useState, useRef, useMemo } from "react";
import { sphereColors } from "@styles/common-colors";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { getSvgColoredIconCss } from "@styles/common-styles";
import { useAppSelector } from "@store/store-helper";
import {
  defaultOpenProjectTargetSelector,
  isSphereXGExclusiveWorkspaceSelector,
} from "@store/sdb-company/sdb-company-selector";
import { OpenProjectTarget } from "@custom-types/sdb-company-types";
import { BaseProjectProps } from "@custom-types/project-types";
import { Link } from "react-router-dom";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { hoverStyle, clickStyle } from "@utils/ui-utils";

interface Props extends BaseProjectProps {
  /** Flag to show a loading state */
  isLoading?: boolean;
}

/**
 * Button to open a project in the Sphere Viewer or WebEditor
 */
export function OpenProjectButton({ project, isLoading }: Props): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();
  const defaultOpenProjectTarget = useAppSelector(
    defaultOpenProjectTargetSelector
  );
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const isSphereXGExclusiveWorkspace = useAppSelector(
    isSphereXGExclusiveWorkspaceSelector
  );

  /**
   * Defines the target that this project should be opened in.
   */
  const openTargetProps = useMemo(() => {
    // The default target.
    const target = defaultOpenProjectTarget;
    const targetName = getOpenProjectTargetName(target);
    const targetHref = getOpenProjectHref({
      openTarget: target,
      projectId: project.id,
    });
    // The target that is not the default one.
    const otherTarget =
      defaultOpenProjectTarget === OpenProjectTarget.sphereViewer
        ? OpenProjectTarget.webEditor
        : OpenProjectTarget.sphereViewer;
    const otherTargetName = getOpenProjectTargetName(otherTarget);
    const otherTargetHref = getOpenProjectHref({
      openTarget: otherTarget,
      projectId: project.id,
    });

    return {
      target,
      targetName,
      targetHref,
      otherTarget,
      otherTargetName,
      otherTargetHref,
    };
  }, [defaultOpenProjectTarget, project.id]);

  /** Returns the button to open the project in the default platform */
  const openProjectButton = useMemo(() => {
    const openText = isSphereXGExclusiveWorkspace
      ? "Open Project"
      : `Open in ${openTargetProps.targetName}`;

    return (
      <FaroButton
        sx={{
          ml: "10px",
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "none",
        }}
        to={openTargetProps.targetHref}
        target={OPEN_PROJECT_TARGET_ATTRIBUTE}
        onClick={() => {
          triggerTrackOpenProject({
            clickType: "left button click",
            openTarget: openTargetProps.target,
          });
        }}
        onAuxClick={() => {
          triggerTrackOpenProject({
            clickType: "middle button click",
            openTarget: openTargetProps.target,
          });
        }}
        onContextMenu={() => {
          triggerTrackOpenProject({
            clickType: "open context menu",
            openTarget: openTargetProps.target,
          });
        }}
      >
        {openText}
      </FaroButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We don't need triggerTrackOpenProject as a dependency
  }, [openTargetProps, isSphereXGExclusiveWorkspace]);

  function handleClose(event: Event): void {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpen(false);
  }

  /**
   * Tracks whenever a user opens a project, including the number of members and the target
   */
  async function triggerTrackOpenProject({
    clickType,
    openTarget,
  }: Pick<OpenProjectProps, "clickType" | "openTarget">): Promise<void> {
    await trackOpenProject({
      openFrom: "projectDetails",
      numberOfMembers: project.members.length || undefined,
      openTarget,
      clickType,
      trackAsyncEvent,
    });
  }

  if (isLoading) {
    return <Skeleton variant="rounded" width={"122px"} height="36px" />;
  }

  if (isSphereXGExclusiveWorkspace) {
    // For Sphere XG exclusive workspaces we don't need a dropdown button, only a simple button.
    return openProjectButton;
  }

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        sx={{
          "&.MuiButtonGroup-root .MuiButtonGroup-firstButton": {
            borderRightColor: sphereColors.blue600,
          },
        }}
      >
        {openProjectButton}
        <FaroIconButton
          component={ArrowDropDownIcon}
          iconSize="18px"
          buttonSize="36px"
          color={sphereColors.pureWhite}
          onClick={() => setIsOpen((wasPrevOpen) => !wasPrevOpen)}
          iconButtonProps={{
            sx: {
              backgroundColor: isOpen
                ? sphereColors.blue700
                : sphereColors.blue500,
              border: `1px solid ${sphereColors.blue600}`,
              // Add a negative left margin to avoid having the border of both buttons form a border of double width
              marginLeft: "-1px",
              borderRadius: "0px 4px 4px 0px",
              "&:hover": {
                ...hoverStyle,
                backgroundColor: sphereColors.blue600,
                ...getSvgColoredIconCss(sphereColors.pureWhite),
              },
              "&:active": {
                backgroundColor: sphereColors.blue700,
              },
              "& svg": {
                transform: isOpen ? "rotate(-180deg)" : "",
                transitionDuration: "0.3s",
                transitionProperty: "transform",
              },
            },
          }}
        />
      </ButtonGroup>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
      >
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ padding: "4px", marginTop: "4px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem sx={{ padding: "4px" }}>
                  <Link
                    target={OPEN_PROJECT_TARGET_ATTRIBUTE}
                    to={openTargetProps.otherTargetHref}
                    style={{
                      color: sphereColors.gray800,
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        triggerTrackOpenProject({
                          clickType: "left button click",
                          openTarget: openTargetProps.otherTarget,
                        });
                      }}
                      onAuxClick={() => {
                        triggerTrackOpenProject({
                          clickType: "middle button click",
                          openTarget: openTargetProps.otherTarget,
                        });
                      }}
                      onContextMenu={() => {
                        triggerTrackOpenProject({
                          clickType: "open context menu",
                          openTarget: openTargetProps.otherTarget,
                        });
                      }}
                      disableRipple
                      sx={{
                        padding: "8px",
                        fontSize: "12px",
                        "&:hover": {
                          color: sphereColors.blue500,
                        },
                        "&:active": {
                          ...clickStyle,
                        },
                      }}
                    >
                      Open in {openTargetProps.otherTargetName}
                    </MenuItem>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
